import React from 'react';


export const initialPlatforms = {
    facebook: false,
    twitter: false,
    linkedin: false,
    instagram: false,
    google: false,
    snapchat: false,
    tiktok: false,
    youtube: false
};


function platformReducer(state, item) {
    let oppState = !state[item];

    Object.keys(state).forEach((key, value) => {
        if (key === item) {
            state[key] = oppState;
        }
    });
    return { state }
}



const platforms = React.createContext({ initialPlatforms, platformReducer });

export default platforms;