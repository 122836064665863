// Import necessary Recoil modules
import { atom } from 'recoil';

// // Create an atom with three values
// export const aiOptionsAtom = atom({
//   key: 'aiOptionsAtom',
//   default: {
//     industry: '',
//     message: '',
//     targetAudience: '',
//   },
// });

export const aiindustry = atom({
    key: 'aiindustry',
    default: ""
})
export const aimessage = atom({
    key: 'aimessage',
    default:""
})
export const aitargetaudience= atom({
    key: 'aitargetaudience',
    default:""
})

export const ainame= atom({
    key: 'ainame',
    default:""
})

export const aimail= atom({
    key: 'aimail',
    default:""
})

export const PlatformAtom = atom({
    key: 'PlatformAtom',
    default: []
})