import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  aiindustry,
  aimessage,
  aitargetaudience,
} from "../components/atoms/atom";
import { useRecoilState } from "recoil";

const SelectIndustry = ({ match }) => {
  const [industry, setIndustry] = useRecoilState(aiindustry);
  const [message, setMessage] = useRecoilState(aimessage);
  const [targetAudience, setTargetAudience] = useRecoilState(aitargetaudience);
  const history = useHistory();
  // const [firstPart, setFirstPart] = React.useState("")
  // const [secondPart, setSecondpart] =React.useState("")
  const [count, setCount] = React.useState(0);
  let Option = localStorage.getItem("selectedOption");

  // console.log(industry,"industry")
  // console.log(message, "message")
  // console.log(targetAudience, "target audience")

  const handleChange = (e) => {
    const { value } = e.target;

    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      if (count === 1) {
        setMessage(value);
      } else if (count === 2) {
        setTargetAudience(value);
      } else {
        setIndustry(value);
      }
    }
  };

  // const navigateToNext = () => {
  //   const { option } = match?.params;
  //   if (!option) return;

  //   if (+option === 1) {
  //     if (count === 1) {
  //       setCount(2);
  //     } else if (count === 2) {
  //       const path = '/contact';
  //       history.push(path);
  //     }
  //   }
  //   // Uncomment and modify this part if needed
  //   // const path = +option === 1 ? '/platforms' : '/intro';
  //   // const state = {
  //   //   option: +option,
  //   //   industry: industry,
  //   // };
  //   // history.push(path, state);
  // };
  const [error, setError] = useState(false);

  const navigateToNext = () => {
    if (!inputHasContent()) {
      setError(true);
      return;
    }
    setError(false);
    const { option } = match?.params;
    localStorage.setItem("option", option);
    if (+option === 1) {
      setCount(1);
      if (count === 1) {
        setCount(2);
      } else if (count === 2) {
        const path = `/contact-new`;
        history.push(path);
      }
    } else if (option === "2b") {
      const state = {
        option: +option,
        industry: industry,
      };
      const path = "/platforms";
      history.push(path, state);
    } else {
      const state = {
        option: +option,
        industry: industry,
      };
      const path = "/allplatforms";
      history.push(path, state);
    }
  };

  const inputHasContent = () => {
    const inputValue =
      count === 1 ? message : count === 2 ? targetAudience : industry;
    return inputValue.trim() !== "";
  };
  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      if (inputHasContent()) {
        navigateToNext();
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="page-3 page-content">
        <div className="page-text">
          <div className="text-medium">
            {count === 1
              ? "WHAT KEY MESSAGE WOULD"
              : count === 2
              ? "Who is your"
              : "What Industry"}
          </div>
          <div className="text-big">
            <span className="red-text">
              {count === 1
                ? "you LIKE TO HIGHLIGHT?"
                : count === 2
                ? "target audience? "
                : "are you in?"}
            </span>
          </div>
        </div>
        <label className="input-label">
          {count === 1
            ? "Message"
            : count === 2
            ? "Target audience? "
            : "Industry name"}
        </label>
        <div className="input-btn-wrap">
          <input
            type="text"
            className="page-3-input input"
            placeholder={
              count === 1
                ? "Message"
                : count === 2
                ? "Target Audience"
                : "Industry"
            }
            value={
              count === 1 ? message : count === 2 ? targetAudience : industry
            }
            onChange={handleChange}
            onKeyDown={onKeyDownHandler}
          />
          <button onClick={navigateToNext} className="btn">
            Next
          </button>
        </div>
        {error ? <span className="error-text">Required*</span> : null}
      </div>
      {/* <div
        className="scroll-page"
        style={{ display: industry?.length ? "flex" : "none" }}
        onClick={navigateToNext}
      >
        <div class="bounce animated">
          <div className="scroll-page-icon"></div>
        </div>
      </div> */}
    </div>
  );
};

export default SelectIndustry;
