import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';


function Header() {
 
    const [status, setData] = useState(null)
    const [delayHandler, setDelayHandler] = useState(null)

    const handleMouseEnter = event => {
        const yourData = true

            setData(yourData)
    }

    const handleMouseLeave = () => {
        // clearTimeout(delayHandler)
        setDelayHandler(setTimeout(() => {
            const yourData = false

            setData(yourData)
        },2500 ))
    }




    
    
    let history = useHistory();

    return (
        <div className="header">
            
            
            <div className="header-logo" onClick={ () => history.push("/")}>
                {/* <Link to="/"></Link> */}
            </div>
            
            
            <div className="header-menu-list" style={{
                display: (status) ? 'flex' : 'none',
                alignItems: 'baseline',
                fontWeight: 300,
                fontSize: '3 em',}}>
                   
                    <div className="header-menu-item"><Link to="/" >HOME.</Link></div>
                    <div className="header-menu-item"><Link to="/intronew" >RECOMMENDATION.</Link></div>
                    <div className="header-menu-item"><Link to="/philosophy" >OUR PHILOSOPHY.</Link></div>
                    <div className="header-menu-item"><Link to="/services" >SERVICES.</Link></div>
                    <div className="header-menu-item"><Link to="/contact-main" >CONTACT US.</Link></div>
                    

            </div>
            
            <div className="header-dots" onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}></div>
            
        </div>
        
    );
}

export default Header;