import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Contact,
  Form,
  Home,
  Insights,
  Services,
  Philosophy,
  NoBudget,
  Amount,
  AllPlatforms,
  Platforms,
  Confirm,
  SelectIndustry,
  IntroNew,
  AiContent,
  ContactNew,
  ContactMain
} from './views';


function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/intro' exact component={Form} />
      <Route path='/select-industry/:option' exact component={SelectIndustry} />
      <Route path='/contact' exact component={Contact} />
      <Route path='/insights' exact component={Insights} />
      <Route path='/services' exact component={Services} />
      <Route path='/philosophy' exact component={Philosophy} />
      <Route path='/nobudget' exact component={NoBudget} />
      <Route path='/amt' exact component={Amount} />
      <Route path='/allplatforms' exact component={AllPlatforms} />
      <Route path='/platforms' exact component={Platforms} />
      <Route path='/confirm' exact component={Confirm} />
      <Route path='/intronew' exact component={IntroNew} />
      <Route path= '/Aicontent' exact component={AiContent} />
      <Route path= '/contact-new' exact component={ContactNew} />
      <Route path= '/contact-main' exact component={ContactMain} />
      
    </Switch>
  );
}

export default Routes;
