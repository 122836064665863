function commarize(min) {
    min = min || 1e3;
    if (this >= min) {
        var units = ["k", "M", "B", "T"];

        var order = Math.floor(Math.log(this) / Math.log(1000));
        var unitname = units[(order - 1)];
        var num = Math.floor(this / 1000 ** order);

        return num + unitname
    }
    return Math.floor(this.toLocaleString())
}

Number.prototype.commarize = commarize
String.prototype.commarize = commarize



export default commarize;