import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import Helmet from "react-helmet";

function services(props) {
  let title = "Get the Best AI Creative Services - On the Dot";
  let description =
    "Discover creative AI-powered services for your business. From AI-driven branding to data analytics, our services empower your business to thrive in the digital era.";
  return (
    <div className="wrapper">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
      </Helmet>
      <div className="page-12 page-content">
        <div className="page-text">
          <h1 className="seo-tag">
            <div className="text-big">
              Services<span className="red-text">.</span>
            </div>
          </h1>
        </div>

        <div className="page-12-grid-container">
          <div className="page-12-grid">
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      CONTENT <br /> CREATION<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      FROM CONCEPT TO DELIVERY, WE CREATE COMPREHENSIVE DIGITAL
                      & SOCIAL MEDIA CONTENT, SPANNING FROM STATIC VISUALS TO
                      ANIMATIONS FOR YOUR BRAND.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      Community <br /> Management
                      <span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      INITIATING, OVERSEEING, AND MODERATING YOUR BRAND'S SOCIAL
                      MEDIA PRESENCE AND CONTENT.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      REPORTING & ANALYSIS<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      WE MONITOR YOUR SOCIAL AND DIGITAL METRICS TO ENSURE YOUR
                      GOALS ARE MET, OFFERING ANALYSIS AND SUGGESTIONS FOR
                      ENHANCED PERFORMANCE.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      INFLUENCER <br /> MANAGEMENT
                      <span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      IDENTIFYING, REACHING OUT, NEGOTIATING, ENGAGING, AND
                      MAINTAINING RELATIONSHIPS WITH INFLUENCERS TO ELEVATE YOUR
                      BRAND'S PRESENCE.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      DIGITAL <br /> STRATEGY<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      WE OFFER A STRATEGIC ROADMAP FOR YOUR DIGITAL SUCCESS,
                      ENCOMPASSING GOAL SETTING, PLANNING, CONCEPTUALIZATION,
                      AND EXECUTION.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      MEDIA
                      <br />
                      PLANNING<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      ASSESSING THE OPTIMAL LOCATIONS, TIMINGS, AND METHODS FOR
                      ENHANCING YOUR MEDIA CAMPAIGNS TO MEET YOUR OBJECTIVES AND
                      KPIS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      MEDIA
                      <br />
                      BUYING<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      WE IMPLEMENT AND FINE-TUNE YOUR MEDIA CAMPAIGNS TO
                      EFFECTIVELY MEET YOUR GOALS AND KEY PERFORMANCE
                      INDICATORS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxesContainer">
              <div class="cardBox">
                <div className="card">
                  <div class="front">
                    <h3>
                      SOCIAL
                      <br />
                      CONSULTING<span className="fontdot">.</span>
                    </h3>
                  </div>
                  <div class="back">
                    <p>
                      GUIDING ON BEST PRACTICES, CURRENT TRENDS, AND
                      OPPORTUNITIES, ALONG WITH TAILORED STRATEGIES FOR
                      CAPITALIZING ON THEM FOR YOUR BRAND.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-page">
          <div class="bounce animated">
            <Link
              to={{
                pathname: "/intronew",
                state: props.location.state,
              }}
            >
              <div className="scroll-page-icon"></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default services;
