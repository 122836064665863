import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  ainame,
  aiindustry,
  aimessage,
  aimail,
  aitargetaudience,
} from "../components/atoms/atom";
import Loader from "../components/Loader";

const AiContent = () => {
  const [response, setresponse] = useState("");
  const [isloading, setisloading] = useState(true);
  const [name, setName] = useRecoilState(ainame);
  const [mail, setEmail] = useRecoilState(aimail);

  const [industry, setIndustry] = useRecoilState(aiindustry);
  const [message, setMessage] = useRecoilState(aimessage);
  const [targetaudience, setTargetaudience] = useRecoilState(aitargetaudience);
  // const name = useRecoilValue(ainame);
  // const mail = useRecoilValue(aimail);
  const postData = async () => {
    const apiUrl = "https://api.onthedot.me/form-submit";
    const postDataObject = {
      name: name,
      email: mail,
      campaign: message,
      audience: targetaudience,
      industry: industry,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postDataObject),
      });

      const data = await response.json();
      setresponse(data?.response?.replace(/"/g, ""));
      setisloading(false);
      setEmail("");
      setName("");
      setIndustry("");
      setMessage("");
      setTargetaudience("");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    postData();
  }, []);

  return (
    <div className="wrapper">
      <div className="page-7 page-8 page-special page-content">
        <div className="page-text">
          <div className="text-medium">
            AI
            <span className="red-text">Recommendation.</span>
          </div>
          {isloading ? (
            <Loader />
          ) : (
            <>
            <div className="text-small ai-content">{response}</div>
            {
              response && (
                <span className="red-text">
                WHILE THIS CONCEPT ORIGINATED FROM AI-GENERATED INSIGHTS, OUR
                PROFESSIONAL EXPERTISE AND EXPERIENCE CAN HELP ELEVATE YOUR
                CAMPAIGN.
              </span>
              )
            }
          </>
          )}

          {/* <div className="text-small">
          but it's not just about the number of impressions, It's about the
          quality.
        </div> */}
          {/* <Loader /> */}
          <div className="text-small" style={{ fontWeight: 600 }}>
            <Link
              to={{
                pathname: "/contact",
                // state: props.location.state,
              }}
            >
              {/* <span className="red-text"> Contact Us </span> WHILE THIS CONCEPT
              ORIGINATED FROM AI-GENERATED INSIGHTS, OUR PROFESSIONAL EXPERTISE
              AND EXPERIENCE CAN HELP ELEVATE YOUR CAMPAIGN.{" "}
              <span className="fontdot">.</span> */}
            </Link>
          </div>
        </div>

        {/* <div className="page-special-data">
          <div className="page-option">
             <div>{result.commarize()}</div> 
            <div>Impressions</div> 
          </div>
        </div> */}
      </div>
      {/* <Link to="/confirm">
        <div className="scroll-page">
          <div class="bounce animated">
            <div className="scroll-page-icon"></div>
          </div>
        </div>
      </Link> */}
    </div>
  );
};

export default AiContent;
