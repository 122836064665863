import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { aiindustry } from "../components/atoms/atom";

function scroll() {
  let pageHeight = window.innerHeight;
  window.scrollBy(0, pageHeight);
}

function IntroNew() {
  const [option, setOpt] = useState(0);
  const history = useHistory();


  const handleSelectOption = (selectedOption) => {
    setOpt(selectedOption);
    // scroll();
    selectedOption == 1
      ? history.push(`/select-industry/${selectedOption}`)
      : history.push(`/intro`);
  };

  const [industry, setIndustry] = useRecoilState(aiindustry)

  useEffect(()=>{
   setIndustry("")
  },[])
  return (
    <>
      <div className="wrapper">
        <div className="page-2 page-content">
          <div className="page-text">
            <div className="text-medium animate__bounce">WHAT BRINGS YOU</div>
            <div className="text-big">
              <span className="red-text">TO US TODAY? </span>
            </div>
          </div>

          <div className="page-2-options">
            <span
              //  to="/intro"
              className="page-2-option"
              onClick={() => handleSelectOption(1)}
            >
              Social Creative Strategy
            </span>
            <span
              to="/intro"
              className="page-2-option"
              onClick={() => handleSelectOption(2)}
            >
              Digital Media Strategy
            </span>
            {/* <div
              className='page-2-option'
              onClick={() => handleSelectOption(3)}
            >
              Sales
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroNew;
