import React from 'react'

function Confirm(){
    return(
<div className="wrapper">


<div className="page-14">
    <div className="page-text page-content">
        <div className="text-medium">
        <span className="red-text">Thank you</span><span className="fontdot">.</span>
        </div>
        <div className="text-small-phy">
        We will be in touch soon, to help you connect the dots.
       </div>
    </div>
</div >



</div>
    )
}
export default Confirm;