import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function scroll() {
  let pageHeight = window.innerHeight;
  window.scrollBy(0, pageHeight);
}

function Form() {
  const [option, setOpt] = useState();
  const history = useHistory();

  const handleSelectOption = (selectedOption) => {
    setOpt(selectedOption);
    // scroll();

    history.push(`/select-industry/${selectedOption}`);
  };

  return (
    <>
      <div className='wrapper'>
        <div className='page-2 page-content'>
          <div className='page-text'>
            <div className='text-medium animate__bounce'>what are you</div>
            <div className='text-big'>
              <span className='red-text'>Looking to grow?</span>
            </div>
          </div>

          <div className='page-2-options'>
            <div
              className='page-2-option'
              onClick={() => handleSelectOption("2a")}
            >
              Brand Awareness
            </div>
            <div
              className='page-2-option'
              onClick={() => handleSelectOption("2b")}
            >
              Followers
            </div>
            <div
              className='page-2-option'
              onClick={() => handleSelectOption("2c")}
            >
              Sales
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
