import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PlatformContext from "../context";
import { useRecoilValue, useRecoilState } from "recoil";
import { ainame, aimail } from "../components/atoms/atom";

import emailjs from "emailjs-com";

function ContactNew(props) {
  const chosenOption = localStorage.getItem("option");

  const { initialPlatforms, platformReducer } =
    React.useContext(PlatformContext);
  const [name, setName] = useRecoilState(ainame);
  const [mail, setEmail] = useRecoilState(aimail);

  useEffect(() => {
    setName("");
    setEmail("");
  }, []);
  // another optionss

  const receivedState = props.location.state;

  const navigateToAnotherPage = () => {
    const { history } = props;
    const chosenOption = localStorage.getItem("option");
    const newPath = "/insights";

    const modifiedState = {
      ...receivedState,
    };

    history.push(newPath, modifiedState);
    const socialMediaPlatforms = [
      "google",
      "twitter",
      "linkedin",
      "facebook",
      "instagram",
      "snapchat",
      "youtube",
      "tiktok",
    ];

    socialMediaPlatforms.forEach((platform) => {
      if (initialPlatforms[platform]) {
        platformReducer(initialPlatforms, platform);
      }
    });
  };

  // const { initialPlatforms, platformReducer } =
  //   React.useContext(PlatformContext);
  // const [name, setName] = useState("");
  // const [mail, setEmail] = useState("");
  // const [msg, setMsg] = useState("");

  let ind = "";
  let option = "";
  let res = "";
  let amt = "";
  let p = [];
  if (props.location.state) {
    ind = props.location.state.industry;
    option = chosenOption;
    res = props.location.state.result;

    amt = props.location.state.amount;
  }

  Object.keys(initialPlatforms).forEach((key, value) => {
    if (initialPlatforms[key] === true) {
      p.push(key);
    }
  });

  // replace with your email
  function sendMail(e) {
    e.preventDefault();
    emailjs
      .send(
        "service_gjxcyhf",
        "template_2c8u707",
        {
          name: name,
          email: mail,
          industry: ind,
          option:
            option === "2a"
              ? "brand awareness"
              : option === "2b"
              ? "followers"
              :option === "2c"?
               "sales" : null,
          result: `${Math.floor(res)}  impressions or followers`,
          amount: `${amt} USD`,
          platforms: p,
        },
        "K70pqzPmwLthZiZ0A"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const isFormValid = name && mail;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);
    setIsValidEmail(isEmailValid);
  };
  const [isValidName, setIsValidName] = useState(true);
  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setName(inputName);
    const isNameValid = /^[A-Za-z][A-Za-z\s]*$/.test(inputName);
    setIsValidName(isNameValid);
  };

  return (
    <div className="wrapper">
      <div className="page-13 contact-new page-content">
        <div className="page-text">
          <h1 className="seo-tag">
          <div className="text-medium">
            Contact Us<span className="red-text">.</span>
          </div>
          </h1>
          {/* <div className='text-big'>
            <div className='red-text'>
              info@onthedot.me
            </div>
          </div> */}
        </div>
        {!isFormValid ? (
          <p className="alt-text">
            SUBMIT YOUR DETAILS TO VIEW YOUR RECOMMENDATION.
          </p>
        ) : (
          ""
        )}

        <div className="page-13-input-grid">
          <div className="input-wrap">
            <label className="input-label">Your Name</label>
            <input
              className={`name input ${isValidName ? "" : "invalid"}`}
              placeholder="Enter your name"
              onChange={handleNameChange}
              value={name}
              required
            />
            {!isValidName && isFormValid && (
              <p className="error-message">
                Please enter a valid name (only letters).
              </p>
            )}
          </div>
          <div className="input-wrap">
            <label className="input-label">Your email</label>
            <input
              className={`email input ${isValidEmail ? "" : "invalid"}`}
              placeholder="Enter your email"
              onChange={handleEmailChange}
              value={mail}
              required
            />
            {!isValidEmail && isFormValid && (
              <p className="error-message">
                Please enter a valid email address.
              </p>
            )}
          </div>

          <div className="btn-link">
            {/* {isFormValid && isValidEmail && isValidName && chosenOption === 1  ? (
              <Link to={"/Aicontent"}>Submit</Link>
            ) : (
              ""
            )} */}
            {isFormValid && isValidEmail && isValidName && chosenOption == 1 ? (
              <Link to={"/Aicontent"}>Submit</Link>
            ) : chosenOption !== 1 &&
              isFormValid &&
              isValidEmail &&
              isValidName ? (
              <div onClick={sendMail}>
                <button className="btn" onClick={navigateToAnotherPage}>
                  Submit
                </button>
              </div>
            ) : (
              ""
            )}
            {/* <p className='alt-text'>SUBMIT DETAILS TO VIEW YOUR AI DRIVEN RECOMMENDATION.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactNew;
