import React, { useState } from "react";
import { Link } from "react-router-dom";
import PlatformContext from "../context";

import emailjs from "emailjs-com";

import Helmet from "react-helmet";

function ContactMain(props) {
  const chosenOption = localStorage.getItem("option");

  const receivedState = props.location.state;
  const navigateToAnotherPage = () => {
    const { history } = props;
    const chosenOption = localStorage.getItem("option");
    const newPath = "/insights";

    const modifiedState = {
      ...receivedState,
    };

    history.push(newPath, modifiedState);
    const socialMediaPlatforms = [
      "google",
      "twitter",
      "linkedin",
      "facebook",
      "instagram",
      "snapchat",
      "youtube",
      "tiktok",
    ];

    socialMediaPlatforms.forEach((platform) => {
      if (initialPlatforms[platform]) {
        platformReducer(initialPlatforms, platform);
      }
    });
  };

  const { initialPlatforms, platformReducer } =
    React.useContext(PlatformContext);
  const [name, setName] = useState("");
  const [mail, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  let ind = "";
  let option = "";
  let res = "";
  let amt = "";
  let p = [];
  if (props.location.state) {
    ind = props.location.state.industry;
    option = chosenOption;
    res = props.location.state.result;

    amt = props.location.state.amount;
  }

  Object.keys(initialPlatforms).forEach((key, value) => {
    if (initialPlatforms[key] === true) {
      p.push(key);
    }
  });

  function sendMail(e) {
    e.preventDefault();
    emailjs
      .send(
        "service_gjxcyhf",
        "template_2c8u707",
        {
          name: name,
          email: mail,
          message: msg,
          industry: ind,
          option:
            option === "2a"
              ? "brand awareness"
              : option === "2b"
              ? "followers"
              : option === "2c"
              ? "sales"
              : null,
          result: `${Math.floor(res)}  impressions or followers`,
          amount: `${amt} USD`,
          platforms: p,
        },
        "K70pqzPmwLthZiZ0A"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  let title = "Get in Touch - Contact On the Dot Creative Agency";
  let description =
    "Reach out to On the Dot and let's transform your business with AI creativity. Our team is ready to collaborate on innovative ideas that drive you success.";

  return (
    <div className="wrapper">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
      </Helmet>
      <div className="page-13 page-content">
        <div className="page-text">
          <h1 className="seo-tag">
            <div className="text-big">
              Contact Us
              <span className="red-text">.</span>
            </div>
          </h1>
          <div className="text-big">
            <div className="red-text">info@onthedot.me</div>
          </div>
        </div>

        <div className="page-13-input-grid">
          <div className="input-wrap">
            <label className="input-label">Your Name</label>
            <input
              className="name input"
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-wrap">
            <label className="input-label">Your email</label>
            <input
              className="email input"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-wrap textarea-wrap">
            <label className="input-label">Your email</label>
            <textarea
              className="message input"
              placeholder="Message"
              onChange={(e) => setMsg(e.target.value)}
            ></textarea>
          </div>

          <div className="btn-link" onClick={sendMail}>
            {/* <button className="btn"> */}
            <Link to="/intronew">Submit</Link>
            {/* </button> */}

            {/* {chosenOption == 1 ? (
              <Link to="/Aicontent">Submit</Link>
            ) : (
              <button className="btn" onClick={navigateToAnotherPage}>
                Submit
              </button>
            )} */}
            {/* <Link to={chosenOption == 1 ? '/Aicontent' : '/confirm'} >Submit</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMain;
