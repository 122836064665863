import React from "react";
import { Link } from "react-router-dom";
import PlatformContext from "../context";

function Platforms(props) {
  const { initialPlatforms, platformReducer } =
    React.useContext(PlatformContext);

  let x = "var(--red)";
  let y = "var(--black)";
  const [avg, setAvg] = React.useState(0);
  const [c, setC] = React.useState(0);

  function calc(val, status) {
    if (status) {
      // Increment c and update avg
      setC(c + 1);
      setAvg(avg + val);
    } else {
      if (c - 1 >= 0) {
        setC(c - 1);
        setAvg(avg - val);
      }
    }
  }

  // function calc(val, status) {
  //   console.log(val, status ,"work")

  //   if (!status) {
  //     setC(c + 1);
  //     setAvg(avg + val);
  //   } else {
  //     setC(c - 1);
  //     setAvg(avg - val);
  //   }
  // }

  return (
    <div className="wrapper">
      <div className="page-5 page-content">
        <div className="page-text">
          <div className="text-medium">What platforms do you</div>
          <div className="text-big">
            <span className="red-text">Want to be active on?</span>
          </div>
        </div>
        <div className="social-media-icons">
          <div
            className={`social-media-icon instagram ${
              initialPlatforms.instagram ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "instagram");
              calc(1.8, initialPlatforms.instagram);
            }}
          ></div>
          <div
            className={`social-media-icon twitter ${
              initialPlatforms.twitter ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "twitter");
              calc(1.37, initialPlatforms.twitter);
            }}
          ></div>
          <div
            className={`social-media-icon facebook ${
              initialPlatforms.facebook ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "facebook");
              calc(0.255, initialPlatforms.facebook);
            }}
          ></div>
          <div
            className={`social-media-icon tiktok ${
              initialPlatforms.tiktok ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "tiktok");
              calc(0.075, initialPlatforms.tiktok);
            }}
          ></div>
        </div>
      </div>
      <div className="scroll-page">
        <Link
          to={{
            pathname: "/amt",
            state: {
              ...props.location.state,
              res: avg / c,
            },
          }}
        >
          <div className="scroll-page-icon"></div>
        </Link>
      </div>
    </div>
  );
}

export default Platforms;
