import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { aiindustry } from '../components/atoms/atom';
function NoBudget(props) {

  let history = useHistory();
  const [industry, setIndustry] = useRecoilState(aiindustry)
  // let screen = props.location.state.option;

  // if (!screen) history.push('/services');
  useEffect(()=>{
    setIndustry("")
    },[])

  let screen = localStorage.getItem('option');
  return (
    <div>
      {screen === "2a" && (
        <div className='wrapper'>
          <div className='page-8 page-special page-content'>
            <div className='page-text'>
              <div className='text-medium'>
                Our 
                <span className='red-text'>
                 Recommendation<span className='red-text'>.</span>
                </span>
              </div>
              <div className='text-small'>
                WITHOUT AN ALLOCATED BUDGET, YOU WILL DEFINITELY NEED A MORE
                STRATEGIC APPROACH TO BRAND AWARENESS.
              </div>

              <div className='text-small' style={{ fontWeight: 600 }}>
                <Link
                  to={{
                    pathname: '/contact',
                    state: props.location.state,
                  }}
                >
                  <span className='red-text'>Contact Us </span>FOR AN IN-DEPTH
                  PLAN.
                </Link>
              </div>
            </div>

            {/* <div className='page-special-data'>
              <div className='page-option'>
                <div className='font-inter'>0</div>
                <div className='font-inter'>Impressions</div>
              </div>
            </div> */}
          </div>
        </div>
      )}

      {screen === "2b" && (
        <div className='wrapper'>
          <div className='page-10 page-special page-content'>
            <div className='page-text'>
              <div className='text-medium'>
                <span className='red-text'>
                  Our Recommendation<span className='fontdot'>.</span>
                </span>
              </div>
              <div className='text-small'>
                TO GROW YOUR FANBASE WITHOUT A MEDIA BUDGET, YOU’LL NEED TO
                DEVELOP A MORE STRATEGIC APPROACH
                <span className='fontdot'>.</span>
              </div>

              {/* <div className='text-small' style={{ fontWeight: 600 }}>
                <span className='red-text'>
                  <Link
                    to={{
                      pathname: '/contact',
                      state: props.location.state,
                    }}
                  >
                    <span className='red-text'>Contact Us </span>FOR AN IN-DEPTH
                    PLAN<span className='fontdot'>.</span>
                  </Link>
                </span>
              </div> */}
            </div>

            {/* <div className='page-special-data'>
              <div className='page-option'>
                <div>0</div>
                <div>Followers</div>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {screen === 3 &&
        history.push('/insights', {
          ...props.location.state,
        })}
      <div className='scroll-page'>
        <Link to='/services'>
          <div className='scroll-page'>
            <div class='bounce animated'>
              <div className='scroll-page-icon'></div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NoBudget;