import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import commarize from "../components/Commarize";
import { useRecoilState } from "recoil";
import { aiindustry } from "../components/atoms/atom";
import { PlatformsContext } from "../context";

function Insight(props) {


  const chosenOption = localStorage.getItem("option");
  const [industry, setIndustry] = useRecoilState(aiindustry)

  const { option, result } = props?.location?.state;
  // console.log(props, "res")


  
  
useEffect(()=>{
setIndustry("")
},[])

  
  let num = Math.floor(result)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//  console.log(num, "propssss")
 

  return (
    <div className="wrapper">
      {/* chatgpt recommendation */}
      {/* {chosenOption == 1 ? (
        // <div className="page-7 page-8 page-special page-content">
        //   <div className="page-text">
        //     <div className="text-medium">
        //       Our
        //       <span className="red-text">Recommendation.</span>
        //     </div>
        //     <div className="text-small">
        //       Based on the budget and objectives selected, you can achieve
        //       approximately <span className="red-text-bold"> {num}</span>{" "}
        //       impressions.
        //     </div>

        //     <div className="text-small">
        //       but it's not just about the number of impressions, It's about the
        //       quality.
        //     </div>

        //     <div className="text-small" style={{ fontWeight: 600 }}>
        //       <Link
        //         to={{
        //           pathname: "/contact",
        //           state: props.location.state,
        //         }}
        //       >
        //         <span className="red-text"> Contact Us </span> for a more
        //         in-depth plan<span className="fontdot">.</span>
        //       </Link>
        //     </div>
        //   </div>

        //   <div className="page-special-data">
        //     <div className="page-option">
        //       <div>{result.commarize()}</div>
        //       <div>Impressions</div>
        //     </div>
        //   </div>
        // </div>
        <InsightSocialCreativeStartegy/>
      ) : null} */}

      {chosenOption === "2a" ? (
        <div className="page-7 page-8 page-special page-content">
          <div className="page-text">
            <div className="text-medium">
              Our
              <span className="red-text">Recommendation.</span>
            </div>
            <div className="text-small">
              Based on the budget and objectives selected, you can achieve
              approximately <span className="red-text-bold"> {num}</span>{" "}
              impressions.
            </div>

            <div className="text-small">
              but it's not just about the number of impressions, It's about the
              quality.
            </div>

            {/* <div className="text-small" style={{ fontWeight: 600 }}>
              <Link
                to={{
                  pathname: "/contact",
                  state: props.location.state,
                }}
              >
                <span className="red-text"> Contact Us </span> for a more
                in-depth plan<span className="fontdot">.</span>
              </Link>
            </div> */}
          </div>

          {/* <div className="page-special-data">
            <div className="page-option">
              <div>{result?.commarize()}</div>
              <div>Impressions</div>
            </div>
          </div> */}
        </div>
      ) : null}

      {/* page 9 */}
      {chosenOption === "2b" && (
        <div className="page-9 page-special page-content">
          <div className="page-text">
            <div className="text-medium">
              <span className="red-text">
                Our Recommendation <span className="fontdot">.</span>
              </span>
            </div>
            <div className="text-small">
              GIVEN THE BUDGET ALLOCATED AND THE OBJECTIVE SELECTED YOU CAN GAIN{" "}
              <span className="red-text-bold"> {num}</span> FOLLOWERS.
            </div>

            <div className="text-small">
              HOWEVER, WHEN IT COMES TO FOLLOWER GROWTH, YOU’RE LOOKING FOR
              LOYALTY, NOT JUST NUMBERS.
            </div>

            {/* <div className="text-small" style={{ fontWeight: 600 }}>
              <Link
                to={{
                  pathname: "/contact",
                  state: props.location.state,
                }}
              >
                <span className="red-text">Contact Us </span>for a more in-depth
                plan<span className="fontdot">.</span>
              </Link>
            </div> */}
          </div>

          {/* <div className="page-special-data">
            <div className="page-option">
              <div>{result?.commarize()}</div>
              <div>Followers</div>
            </div>
          </div> */}
        </div>
      )}

      {/* page 11 */}
      {chosenOption === "2c" && (
        <div className="page-11 page-special page-content">
          <div className="page-text">
            <div className="text-medium">
              <span className="red-text">
                Our Recommendation<span className="fontdot">.</span>
              </span>
            </div>
            <div className="text-small">
              THIS OBJECTIVE IS NOT SOMETHING WHICH CAN BE ANSWERED IN 4 SIMPLE
              CLICKS.
            </div>

            <div className="text-small">
              IN ORDER TO ACHIEVE OR INCREASE SALES, YOU’LL NEED A CONTENT,
              MEDIA, AND TARGETING PLAN TO START.
            </div>

            <div className="text-small" style={{ fontWeight: 600 }}>
              {/* <Link
                to={{
                  pathname: "/contact",
                  state: props.location.state,
                }}
              >
                <span className="red-text">Contact Us </span>TO GET STARTED
                <span className="fontdot">.</span>
              </Link> */}
            </div>
          </div>
        </div>
      )}

      {/* <Link
        to={{
          pathname: "/contact",
          state: props.location.state,
        }}
      >
        <div className="scroll-page">
          <div class="bounce animated">
            <div className="scroll-page-icon"></div>
          </div>
        </div>
      </Link> */}
    </div>
  );
}

export default Insight;
