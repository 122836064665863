import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PlatformAtom } from "../components/atoms/atom";

import PlatformContext from "../context";

function AllPlatforms(props) {
  const { initialPlatforms, platformReducer } =
    React.useContext(PlatformContext);

  const [platforms, setPlatforms] = useRecoilState(PlatformAtom);
  const [avg, setAvg] = useState(0);
  const [c, setC] = useState(0);
  let x = "var(--red)";
  let y = "var(--black)";

  function calc(val, status) {
    //  console.log(val, status, "val");

    if (status) {
      // Increment c and update avg
      setC(c + 1);
      setAvg(avg + val);
    } else {
      if (c - 1 >= 0) {
        setC(c - 1);
        setAvg(avg - val);
      }
    }
  }
  // function calc(val, status) {
  //   console.log(val ,"lol")
  //   console.log(status, "val")

  //   if (!status) {
  //     setC(c + 1);
  //     setAvg(avg + val);
  //   } else {
  //     setC(c - 1);
  //     setAvg(avg - val);
  //   }
  // }
  // let p = [];
  // Object.keys(initialPlatforms).forEach((key, value) => {
  //   if (initialPlatforms[key] === true) {
  //     p.push(key);
  //   }
  // });

 

  // console.log(p);
  // console.log(platforms, "platforms")
  

  return (
    <div className="wrapper">
      <div className="page-4 page-content">
        <div className="page-text">
          <div className="text-medium">What platforms do you </div>
          <div className="text-big">
            <span className="red-text">Want to be active on? </span>
          </div>
        </div>
        <div className="social-media-icons">
          <div
            className={`social-media-icon google ${
              initialPlatforms.google ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "google");
              calc(0.66, initialPlatforms.google);
            }}
          ></div>
          <div
            className={`social-media-icon twitter ${
              initialPlatforms.twitter ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "twitter");
              calc(2.7, initialPlatforms.twitter);
            }}
          ></div>
          <div
            className={`social-media-icon linkedin ${
              initialPlatforms.linkedin ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "linkedin");
              calc(14.745, initialPlatforms.linkedin);
            }}
          ></div>
          <div
            className={`social-media-icon facebook ${
              initialPlatforms.facebook ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "facebook");
              calc(1.2, initialPlatforms.facebook);
            }}
          ></div>
          <div
            className={`social-media-icon instagram ${
              initialPlatforms.instagram ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "instagram");
              calc(1.785, initialPlatforms.instagram);
            }}
          ></div>
          <div
            className={`social-media-icon snapchat ${
              initialPlatforms.snapchat ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "snapchat");
              calc(0.81, initialPlatforms.snapchat);
            }}
          ></div>
          <div
            className={`social-media-icon youtube ${
              initialPlatforms.youtube ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "youtube");
              calc(2.595, initialPlatforms.youtube);
            }}
          ></div>
          <div
            className={`social-media-icon tiktok ${
              initialPlatforms.tiktok ? "active" : ""
            }`}
            onClick={() => {
              platformReducer(initialPlatforms, "tiktok");
              calc(0.705, initialPlatforms.tiktok);
            }}
          ></div>
        </div>
      </div>

      <div className="scroll-page">
        <Link
          to={{
            pathname: "/amt",
            state: {
              ...props.location.state,
              res: avg / c,
              platforms: initialPlatforms,
            },
          }}
        >
          <div className="scroll-page-icon"></div>
        </Link>
      </div>
    </div>
  );
}

export default AllPlatforms;
