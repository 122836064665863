import React, { useState } from "react";
import { Link } from "react-router-dom";
import PlatformContext from "../context";

import emailjs from "emailjs-com";

function Contact(props) {
  const chosenOption = localStorage.getItem("option");
  // console.log(props, "lollllllllllll");
  const receivedState = props.location.state;
  const navigateToAnotherPage = () => {
    const { history } = props;
    const chosenOption = localStorage.getItem("option");
    const newPath = "/insights";

    const modifiedState = {
      ...receivedState,
    };

    history.push(newPath, modifiedState);
    const socialMediaPlatforms = [
      "google",
      "twitter",
      "linkedin",
      "facebook",
      "instagram",
      "snapchat",
      "youtube",
      "tiktok",
    ];

    socialMediaPlatforms.forEach((platform) => {
      if (initialPlatforms[platform]) {
        platformReducer(initialPlatforms, platform);
      }
    });
  };

  const { initialPlatforms, platformReducer } =
    React.useContext(PlatformContext);
  const [name, setName] = useState("");
  const [mail, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  let ind = "";
  let option = "";
  let res = "";
  let amt = "";
  let p = [];
  if (props.location.state) {
    ind = props.location.state.industry;
    option = chosenOption;
    res = props.location.state.result;

    amt = props.location.state.amount;
  }

  Object.keys(initialPlatforms).forEach((key, value) => {
    if (initialPlatforms[key] === true) {
      p.push(key);
    }
  });

  // replace with your email
  function sendMail(e) {
    e.preventDefault();
    emailjs
      .send(
        "service_r6829od",
        "template_m2b6t6b",
        {
          name: name,
          email: mail,
          message: msg,
          industry: ind,
          option:
            option === "2a"
              ? "brand awareness"
              : option === "2b"
              ? "followers"
              : "sales",
          result: `${Math.floor(res)}  impressions or followers`,
          amount: `${amt} AED`,
          platforms: p,
        },
        "user_Y2uVyDNSIomzxACOmqq08"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className="wrapper">
      <div className="page-13 contact-new page-content">
        <div className="page-text">
          <h1 className="seo-tag">
            <div className="text-big">
              Contact Us
              <span className="red-text">.</span>
            </div>
          </h1>
          {/* <div className="text-big">
            <div className="red-text">info@onthedot.me</div>
          </div> */}
        </div>

        <div className="page-13-input-grid">
          <div className="input-wrap">
            <label className="input-label">Your Name</label>
            <input
              className="name input"
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-wrap">
            <label className="input-label">Your email</label>
            <input
              className="email input"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <div className="input-wrap textarea-wrap">
            <label className="input-label">Your email</label>
            <textarea
              className="message input"
              placeholder="Message"
              onChange={(e) => setMsg(e.target.value)}
            ></textarea>
          </div> */}

          <div className="btn-link" onClick={sendMail}>
            {chosenOption == 1 ? (
              <Link to="/Aicontent">Submit</Link>
            ) : (
              <button className="btn" onClick={navigateToAnotherPage}>
                Submit
              </button>
            )}
            {/* <Link to={chosenOption == 1 ? '/Aicontent' : '/confirm'} >Submit</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
