import React from "react";
import Routes from "./router";
import Header from "./components/Header";
import { RecoilRoot } from "recoil";
import "./main.css";
import data from "./assets/particles.json";
import Particles from "react-particles-js";


function App() {
  window.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );
  return (
    <>
      <RecoilRoot>
        <Header />
        <Routes />
        <Particles id="particles-js" params={data} className="particle-style" />
      </RecoilRoot>
    </>
  );
}

export default App;
