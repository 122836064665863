import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import PlatformContext from "../context"


function Amount(props) {
  const { option, res } = props.location.state;
  const [amt, setAmt] = useState("");
  const history = useHistory();
  function handleamtchange(e) {
    setAmt(e.target.value);
  }
  const salesoption = localStorage.getItem("option");
  const { initialPlatforms, platformReducer } =
  React.useContext(PlatformContext);
  // console.log(initialPlatforms)

  // useEffect(() => {
  //   const socialMediaPlatforms = ["google", "twitter", "linkedin", "facebook", "instagram", "snapchat", "youtube", "tiktok"];
  
  //   socialMediaPlatforms.forEach(platform => {
  //     if (initialPlatforms[platform]) {
  //       platformReducer(initialPlatforms, platform);
  //     }
  //   });
  // }, []);
  


  
// console.log( amt,res , "lol")

// console.log(salesoption, "saleoption")

  // function calc() {
  //   let val = salesoption === "2a" || salesoption === "2b" ? (amt / res) * 1000 : amt / res;
  //   return val;
  // }
    function calc() {
    let val = salesoption === "2a" || salesoption === "2c" ? (amt/res)*1000 : amt / res;
    return val;
  }
  const navigate = () => {
    let path;

    if (salesoption === "2c") {
      path = "/contact-new";
    } else {
      path = amt <= 0 || !res ? "/nobudget" : "/contact-new";
    }

    const state = {
      ...props.location.state,
      amount: amt,
      result: calc(),
    };
    history.push(path, state);
  };

  // const navigate = () => {
  //   let path = '/insights';

  //   if (!(amt <= 0 || !res) && salesoption !== '2c') {
  //     path = '/nobudget';
  //   }

  //   const state = {
  //     ...props.location.state,
  //     amount: amt,
  //     result: calc(),
  //   };

  //   history.push(path, state);
  // };

  return (
    <div className="wrapper">
      <div className="page-6 page-content">
        <div className="page-text">
          <div className="text-medium">How much are you</div>
          <div className="text-big">
            <span className="red-text">LOOKING TO SPEND?</span>
          </div>
        </div>
        <div className="page-6-input-container input-btn-wrap">
          <input
            type="number"
            pattern="[0-9]*"
            className="page-6-input input"
            value={amt}
            onChange={handleamtchange}
            placeholder="00.00 USD"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate();
              }
            }}
          />
          <button className="btn" onClick={navigate}>
            Next
          </button>
        </div>
      </div>

      {/* <div className='scroll-page' onClick={navigate}>
        <div class='bounce animated'>
          <div className='scroll-page-icon'></div>
        </div>
      </div> */}
    </div>
  );
}

export default Amount;
